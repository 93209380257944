.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.border-danger{
  border-color: #FF0000 !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
html, body {-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;}
html{-webkit-text-size-adjust:100%; -ms-text-size-adjust:100%;}
/* @import url('https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400..900&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
body{font-family: "Poppins", sans-serif!important;
  /* width:100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; */padding: 30px 0;
}
/* body{ font-family: "Maven Pro", sans-serif!important;} */
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* body {
  font-family: Arial, sans-serif;
 } */
 .main-wrapper-component{padding:30px 0;}
 .container {
  max-width:62.5rem!important;
}



 h2 {
  color: #333;
 }
 


 .step-four-container {
  display: flex;
  flex-wrap: wrap;
}

.form-group {
  flex: 1 1 calc(50% - 10px); /* Adjust the width as needed */
  margin-right: 10px;
  margin-bottom: 10px;
}

.btn {
  margin-top:10px;
}

#custom-map-id{
  width: 74% !important;}

.label-class{
  font-weight:500 !important;color:#000000;font-size: 1rem;
}

.label-class-pre{
  font-weight: 600 !important;
  font-size: 1rem !important;
  text-wrap: break-word !important;
}
#autoCompleteInput::placeholder{
  font-weight: 500;
  font-style: italic;
}

.arrow-list {
  list-style-type: none;
  padding: 0;margin-top: 30px;gap: 10px;
}

/* Style for list items */
.arrow-list li {
 /* Adjust spacing between arrow and text */
  position: relative;
  font-size: 20px; /* Font size for list items */
  font-weight: 500; /* Make font weight bolder */
}

/* Style for the arrow */
.arrow-list-arrow {
/* Unicode for right arrow */

  left: 0;
  color: black !important;
  font-size: 20px; /* Font size for the arrow */
  font-weight: 500; 
  position: relative;
  bottom: 3px;/* Make font weight bolder */
}

.span-diff{
  font-size: 20px !important;
  bottom: 0px !important;

}

.first-loader-wrapper{
  position: absolute;
}

.main-wrapper-component{
  background: #FFFFFF;
  /* min-height:80vh; */
  border-radius:20px;
  box-shadow: 0 0 10px rgba(0,0,0,0.18);
  border:2px solid #DFE0E5;
}
/* 
.custom-table .t-head{
  background-color: transparent !important;
}

.t-head .t-head-item{
  color: white !important;
  text-align: center !important;
}
.custom-table{
  border-collapse: separate !important;
}
tr:nth-child(odd) {
  background-color: #c1c1c1;
  text-align: center;
}
tr:nth-child(even){
  background-color: #f2f2f2;
  text-align: center;
} */

/* td:nth-child(1) {
  background-color: transparent !important;
}

.t-non-background{
  background-color: white !important;
  
}

.table-label{
  font-size: 14px;
  text-align: right;
  max-height: fit-content;
} */



.small-txt{
  font-size: 10px !important;
  /* text-align: right; */
}


.bigger-text{
  font-size: 36px !important;
  color: white;
}

.img-fluid-auto{
  max-width: 50% !important;
  height: auto;
}

.custom-table{
  width: 100%;
  height: 100%;
}

.table-wrapper{
  /* height: 90vh; */padding:0 3.75rem;
}

.text-light-ul li{
  color: white;
}

/* Hide the original checkbox */



/* Change appearance when checkbox is checked */


.label-class-special{
  white-space: pre-wrap;
}

.btn-radio{color: #000000 !important;border: 0 !important;margin-top: 0px;}
.btnradio .radio-timg-sec .text-light{color: #000000 !important;}
.btnradio{color: #000000 !important;border: 0 !important;margin-top: 0px;}
.btn-radio .radio-timg-sec .text-blue {
  color: #000000;
}
@media only screen and (max-width: 767px) {
  .img-fluid-auto{
    max-width: 30% !important;
  }
}

@media (min-width: 768px) { /* Adjust the width according to your needs */
  .label-class-special {
    white-space: normal; /* Display text in multiple lines on smaller screens */
  }
}
