

// Import Bootstrap SCSS
// @import '../../node_modules/bootstrap/scss/variables';
@import '../../node_modules/bootstrap/scss/functions';
@import '../../node_modules/bootstrap/scss/variables';










@import '../../node_modules/bootstrap/scss/bootstrap.scss';

.css-f6fqhs-MuiFormLabel-root-MuiInputLabel-root{-webkit-transform: translate(14px, 8px) scale(1)!important;
  -moz-transform: translate(14px, 8px) scale(1)!important;
  -ms-transform: translate(14px, 8px) scale(1)!important;
  transform: translate(14px, 8px) scale(1)!important;}

button.danger-radio-red{padding: 0;}
button.danger-radio-dark{padding: 0;}
.radio-timg-sec{display: flex;justify-content: space-between;align-items: center;padding: 0rem;}
.radiotext{font-size: 1rem;font-weight: 500;color: #000;border: 1px solid #707070;margin: 0 10px 0 0;position: relative;
  width: 20px;
  height: 20px;
  border-radius: 100px;}
 .btnradio.active .radiotext:after{position: absolute;content:"";width:12px;height: 12px;background:#DB3832;border-radius: 100px;left: 3px;
    top: 3px;}
.radio-timg-sec svg{height:37px;}
.text-blue{color: #1E437B;}
.text-light{color: #fff;}
.radiotext.svg-light svg path{fill:#fff!important;}
.radiotext.svg-blue svg path{fill:#1E437B!important;}

.danger-radio-dark .radio-timg-sec svg{fill: #000 !important; filter: invert(0%) sepia(0%) saturate(0%);}


.step-main .MuiStepLabel-iconContainer.Mui-active, .step-main .MuiStepLabel-iconContainer.Mui-disabled{padding-right:0!important;}

.step-main .MuiStepLabel-iconContainer.Mui-active .MuiSvgIcon-root, .step-main .MuiStepLabel-iconContainer.Mui-disabled .MuiSvgIcon-root{width: 1.5em!important;
  height: 1.5em!important;}
  .step-main .MuiStepLabel-iconContainer.Mui-disabled svg circle{fill: #000;}
  .step-main .MuiStepLabel-iconContainer svg text{font-size: 0.8rem; font-weight: bold;}

  .step-main .MuiStepLabel-iconContainer.Mui-completed{
    padding-right:0!important;
  } 
  .step-main .MuiStepLabel-iconContainer.Mui-completed .MuiSvgIcon-root{width: 1.5em!important;
    height: 1.5em!important;}
    .map-secstep{margin: 0;width:100%; height:95%; }

.big-title{font-size:1.563rem;line-height: 2.3rem; font-weight: 500;margin:0 auto 10px auto;white-space:wrap;text-align: center;color:#000000;max-width: 620px;}
.big-title1{font-size:1.875rem;line-height:  2.5rem; font-weight: 500;margin:0px auto 0px auto;text-align: center;color:#000000;}

.bd-highlight {padding: 0 3.75rem;}

.btn-right{display:flex; justify-content: flex-end;justify-content: center;}

    .button-wrapper .btn{margin-top: 0;}
    .inputbox{border-radius: 6px;border:2px solid #000;}
    .inputbox:focus-visible{box-shadow: none;
      outline: 0;}
      .first-button-wrapper{display: flex;justify-content: space-between;padding: 0 3.75em;}
      .voter-box-grid-main{max-width:96%;margin: 0 auto 20px;display: flex;justify-content: center;align-items: center;flex-direction: column;}
.voter-box-grid{display: flex;flex-direction: row;gap:1rem;width: 100%;justify-content: center;}
.voter-box{width:18%;display: flex;flex-direction: row;}
.voter-box-grid-main2{max-width:96%;margin: 0 auto 20px;display: flex;justify-content: end;align-items: center;flex-direction: column;}
.voter-box-grid2{display: flex;flex-direction: row;gap:1rem;width: 100%;justify-content: center;}
.voter-box2{width: 15%;display: flex;flex-direction: row;}
.voter-box-btn{ box-shadow:0 10px 15px -3px rgb(0, 0, 0,0.1);display: flex;width: 100%;flex-direction: column;align-items: center;justify-content: center;padding: 10px;}
// .voter-box-btn svg{  filter: invert(0%) sepia(0%) saturate(0%);}
.voter-box-btn.voter-box-btn-red svg{ fill: none !important;filter: none;}
// .voter-box-btn.voter-box-btn-red svg path{stroke:#000;}
// .voter-box-text.text-danger{color:#000 !important;}
.voter-box-grid-main3{max-width: 100%;display: flex;justify-content: center;}


    .voter-box-btn-red, .voter-box-btn-red2{border:2px solid #707070;}
   .voter-box-btn.voter-box-btn-black, .voter-box-btn-black, .voter-box-btn-black2, .voter-box-btn2.voter-box-btn-black2{border:1px solid #DFE0E5!important;}
    
    .voter-box-text, .voter-box-text2{font-size: 0.8rem;font-weight: 500; line-height: 1rem;display: flex;flex-direction: column;margin: 10px 0 0 0;}
    .voter-box-text.text-dark, .voter-box-text2.text-dark{color:#707070!important;}
    .voter-box-btn.voter-box-btn-red.border-danger, .voter-box-btn2.voter-box-btn-red2.border-danger{border:1px solid #DB3832!important;background: #fff;}

    .voter-box-btn2{ box-shadow:0 10px 15px rgb(16, 67, 123,0.1);display: flex;width: 100%;flex-direction: column;align-items: center;justify-content: flex-start;padding: 10px;}
    .fase-svg{height: 120px;width: auto;}

    // .voter-box-btn2 svg{ filter: invert(0%) sepia(0%) saturate(0%);}
    .voter-box-btn2.border-dark svg{ filter: invert(42%) sepia(1%) saturate(17%) hue-rotate(24deg) brightness(104%) contrast(92%);}
    // .voter-box-btn2.voter-box-btn-red2 svg{ fill: none !important;filter: none;}

    .voter-box-btn.voter-box-btn-black svg path, .voter-box-btn-black svg path{
      stroke:rgb(112,112,112);
    }


    .bonne-sec{max-width: 49rem;margin: 0 auto;}
    .bonne-sec .two-col-row{display:flex;flex-direction: row;gap:1rem;}
    .bonne-sec .two-col{display:flex;flex-direction: column;width:49%;margin-bottom:15px;}
    .bonne-sec .bonne-btn-sec{margin: 20px 0;text-align: right;display: flex;justify-content: center;}
    .bonne-sec .label-col{display: flex;flex-direction: column;text-align: center;}
    .bonne-sec .label-col-not-center{display: flex;flex-direction: column;justify-content: flex-start;}

    .question-btn{padding:0px;background: transparent;margin: 0 0 0 5px;display: flex;}
    .question-btn img{width: 24px;}
   .question-modal{}
   .question-modal .que-header{display: flex;justify-content: flex-end;}
   .question-modal .que-header .close-icon{width:25px;height: 25px;border-radius: 100px;border:0px;background:#DB3832;display: flex;
    align-items: center;
    justify-content: center;}
   .question-modal .content-wrapper{color:#000;font-size: 0.9rem;line-height: 1.3rem;max-height: 500px;
    overflow: hidden;overflow-y: scroll;}
   .question-modal .content-wrapper ul.qm-list{color:#000;margin:0 0 15px 20px; padding: 0;}
   .question-modal .content-wrapper ul.qm-list li{color:#000;font-size: 0.9rem;}
   .question-modal .content-wrapper ul.qm-list ul{color:#000;margin:0 0 10px 20px; padding: 0;}

   .contacte-form .label-text{font-size: 0.9rem;line-height: 1.3rem;font-weight: 600;color:#000;max-width: 338px;margin:15px auto;display: flex;text-align: center;}

   .contacte-form{max-width: 49rem;margin: 0 auto;gap: 1rem;display: flex;flex-direction: column;}
   .contacte-form .two-col-row{display:flex;flex-direction: row;gap:1rem;}
   .contacte-form .two-col{display:flex;flex-direction: column;width:49%;}
   .contacte-form .one-col{display:flex;flex-direction: column;width:100%;}
   .contacte-form .label-col{display: flex;flex-direction: column;text-align: center;}
   .contacte-form .label-col .title{font-size: 1.5rem;line-height: 2rem; font-weight: 600;}
   .droup-select{}
   .checkbox-sec{display: flex;flex-direction: column;gap: 0.6rem;}
   .checkbox-sec .checkbox-inr{display: flex;align-items: start;}
   .checkbox-sec .custom-checkbox{display: flex;width: 15px;height: 15px;margin: 4px 10px 0 0;}
   .checkbox-sec label{font-size: 0.7rem;}
   .checkbox-inr a{color:#DB3832;}
    // fill: var(--white-color) !important;
    // filter: invert(.9);

    // .main-wrapper{width: 30%;}
    .sui-button-wrapper{display: flex;
  justify-content: space-between;
  margin: 15px 0;padding: 0 3.75em;}

  .btn{font-family: "Poppins", sans-serif!important;}
 .btn-secondary {
    background: #DB3832!important;
    color: white!important;
    font-size:15px!important;font-weight: 400!important;display: flex;
    align-items: center;
    gap: 10px;border:0;
  }
  .btn-flat {
    background: #fff!important;
    color: #DB3832!important;
    font-size:15px!important;font-weight: 400!important;display: flex;justify-content: center;
    align-items: center;
    gap: 10px;border:1px solid #DB3832;
  }
  .btn-flat:hover, .btn-secondary:hover {
    background:#000!important;
    color: white!important;
  }
  .btn-xxl {
    padding: 1rem 1.5rem;
    font-size: 1.5rem;
  }

  .btn-secondary{background:var(--bs-gray-dark);border-color: var(--bs-gray-dark);}
  .btn-secondary:hover{ background: rgb(var(--bs-danger-rgb));border-color: rgb(var(--bs-danger-rgb))}
  .form-control:disabled {
    background-color:#fff;
    opacity: 1;
  }

  .custom-table .t-head{
    background-color: transparent !important;
  }
  
  .custom-table .t-head-item{
    color: white !important;
    text-align: center !important;
    background: #000000;
  }
  .custom-table-column-one .t-head-item{
    color: white !important;
    text-align: center !important;
    background: #000000;
  }
    .custom-table .t-head-item .table-label{font-weight:600;margin-top:6px;}
    .custom-table-column-one .t-head-item .table-label{font-weight: 600;}


  .custom-table{
    border-collapse: separate !important;
  }
  .custom-table-column-one{
    border-collapse: separate !important;
  }
  .custom-table tr:nth-child(odd) {
    background-color: #FAF7F7;
    text-align: center;
  }
  .custom-table tr:nth-child(even){
    background-color: #EEEEEE;
    text-align: center;
  }
  .custom-table-column-one tr:nth-child(odd) {
    background-color: #c1c1c1;
    text-align: center;
  }
  .custom-table-column-one tr:nth-child(even){
    background-color: #f2f2f2;
    text-align: center;
  }
  
  // .custom-table td:nth-child(1) {
  //   background-color: transparent !important;
  //   /* color: white !important; */
  // }
  
  .custom-table .t-non-background{
    background-color: #fff !important;
    
  }

  .custom-table-column-one .t-non-background{
    background-color: white !important;
    
  }
  
  .custom-table thead td .table-label{
    font-size: 16px;
    text-align: center;
    max-height: fit-content;
    display: flex;flex-direction: column;
      font-size:16px;
      font-weight:500 !important;color:#fff;
  }

  .custom-table td .table-label{
    font-size: 16px;
    text-align: center;
    max-height: fit-content;
    display: flex;flex-direction: column;
      font-size:16px;
      font-weight:500 !important;color:#707070;
  }


  .custom-table-column-one .table-label{
    font-size: 16px;
    text-align: center;
    max-height: fit-content;
    display: flex;flex-direction: column;
  }
  .custom-table td p.est-text{color:#000000;}

  .d-auto-none{
    display: none !important;
  }
  
  th .text-wrapper{display: flex;flex-direction: column;justify-content: flex-end;text-align: right;padding: 0 6px 0 0;}
  th .text-wrapper .table-label{text-align: right;font-size:18px !important;font-weight: 500;color:#000000;display: flex;
    flex-direction: column;}
  
  .custom-table .big-label{
  font-size:16px ;font-weight:500 !important;line-height: 16px;color:#707070;
  }
  
  .custom-table-column-one .big-label{
    font-size:16px !important;font-weight: 500 !important;
  }
  .buildings-button-wrapper{
    margin: 12px 0;display: flex;gap:20px;
  }
  td .text-wrapper{display: none;}
  .custom-table p{color:#000000;}
  .custom-table td p {
    color: #707070;
  }
  
   .MuiInputBase-input.MuiOutlinedInput-input.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input{height: 1em!important;padding: 10px 15px!important;background: #fff !important;}
.css-li58zp-MuiFormLabel-root-MuiInputLabel-root{font-size: 0.9rem!important;overflow: visible!important;top: -8px!important;font-family: "Poppins", sans-serif!important;
  color: rgba(0, 0, 0, 1)!important;}
 .css-1d3z3hw-MuiOutlinedInput-notchedOutline{border-color: #C7CACE!important;}

 .custom-table tr.last-row:nth-child(2n){background: transparent;}

      @media (max-width:768px) {
        .first-button-wrapper, .sui-button-wrapper{flex-direction: column;}
        .voter-box-grid2{flex-wrap: wrap;justify-content: center;}
        .voter-box2{width:30.7%;}
        .bd-highlight {
          padding: 0 1.75rem;
        }
        .first-button-wrapper, .sui-button-wrapper{
          padding: 0 1.75rem;
        }
        .btn-secondary{justify-content: center;}
        .big-title{
          margin:0 15px !important;
        }
        .bd-highlight{margin-top: 15px;}
        .sui-button-wrapper .button-wrapper{margin: 5px 0;}
        .sui-button-wrapper .button-wrapper .btn{width: 100%;}
      }

      .buildings-button-wrapper{
        margin-bottom: 12px;
      }



            @media (max-width:576px) {
              .voter-box2 {
                width: 48%;
              }

              .step-main .MuiStepLabel-iconContainer.Mui-active .MuiSvgIcon-root, .step-main .MuiStepLabel-iconContainer.Mui-disabled .MuiSvgIcon-root{width: 1.1em!important;
                height: 1.1em!important;}
                .step-main .MuiStepLabel-iconContainer.Mui-active .MuiSvgIcon-root, .step-main .MuiStepLabel-iconContainer.Mui-disabled .MuiSvgIcon-root{width: 1.1em!important;
                  height: 1.1em!important;}

                  .step-main .MuiStepLabel-iconContainer.Mui-completed .MuiSvgIcon-root{width: 1.1em!important;
                    height: 1.1em!important;}
      
              .voter-box-grid {
                flex-wrap: wrap;
                justify-content: center;
              }
      
              .map-secstep {
                width: 100%;
                height: 100%;
              }
      
              .big-title {
                font-size: 1rem !important;
                line-height: 1.5rem !important
              }
              .big-title1{
                font-size: 1rem !important;
                line-height: 1.5rem !important
              }

              .custom-table{
                border-collapse: collapse !important;
              }
              .custom-table tr{
                border: 1px solid black;
              }
              .custom-table .table-label{
                font-weight: 500;
              }

              .custom-table td{
                border: 1px solid black;
              }
      
              
      
              .custom-table .table-label {
                font-weight:500 !important;
              }

              // .bd-highlight{
              //   height: 35vh !important;
              // }
      
              .small-custom-txt{
                font-size: 14px !important;
              }
              .voter-box {
                width: 48%;
              }
              .arrow-list li{font-size: 17px;}
              .mob-scr{overflow-y: scroll;height: 500px;}
      
              @media screen and (max-width:400px) {
                // .bd-highlight {
                //   height: 60vh !important;
                // }
                .step-main .MuiStepLabel-iconContainer.Mui-active .MuiSvgIcon-root, .step-main .MuiStepLabel-iconContainer.Mui-disabled .MuiSvgIcon-root{width: 0.8em!important;
                  height: 0.8em!important;}
                  .step-main .MuiStepLabel-iconContainer.Mui-active .MuiSvgIcon-root, .step-main .MuiStepLabel-iconContainer.Mui-disabled .MuiSvgIcon-root{width: 0.8em!important;
                    height: 0.8em!important;}
  
                    .step-main .MuiStepLabel-iconContainer.Mui-completed .MuiSvgIcon-root{width: 0.8em!important;
                      height: 0.8em!important;}

                     .margin-small-0{
                       margin: 0 !important;
                     }
                     .big-title{
                       margin:0 15px !important;
                     }

      
              }
      
              .bonne-sec .two-col-row {
                flex-direction: column;
                gap: 0.6rem;
              }
      
              .bonne-sec .two-col {
                flex-direction: column;
                width: 100%;
              }
      
              .contacte-form .two-col-row {
                flex-direction: column;
                gap: 0.6rem;
              }
      
              .contacte-form .two-col {
                flex-direction: column;
                width: 100%;
              }
      
              // .table-wrapper{overflow: hidden;overflow-x: scroll;}
              // .custom-table {width: 570px;}
      
              .custom-table th {
                display: none;
              }


              .custom-table tr:not(.d-auto-none) {
               background-color: #EEEEEE !important;
              }
       
              .custom-table .d-auto-none{
                display: table-row !important;
                background-color: #FAF7F7 !important;
                text-align: center;
              }
              .custom-table td .text-wrapper .table-label {color:#000;}
              .custom-table td .text-wrapper p{color:#000;}
      
              .custom-table td {
                padding: 6px;
               
              }
              .buildings-button-wrapper{
                margin-bottom: 4px;
              }
      
              td .text-wrapper {
                display: flex;
                flex-direction: column;
              }
              .btn-flat, .btn-secondary {width:100%; justify-content: center;}
              .main-wrapper-component{min-height: 600px;overflow-y: scroll;height: 600px;}
              .custom-table{display: table;table-layout: fixed;}
              .label-class{text-align: center;}
              .voter-box .btn{margin:0;}
              .voter-box-grid{margin-top: 15px;}
             
            }

    .info{
      font-size: 12px;
    }

      @media (max-width:475px) {
        .sui-button-wrapper{flex-direction: column;}
        .sui-button-wrapper .button-wrapper{margin: 5px 0;}
        .sui-button-wrapper .button-wrapper button{width: 100%;}
      
        .voter-box2{width:47%;}
        .voter-box{width:47%;}
      


        .inputbox::placeholder{
          font-size: 11.5px
        }
      }

        .custom-checkbox{
          accent-color: rgb(var(--bs-danger-rgb)) !important;/* Red background color when checked */
          border-color: rbb(var(--bs-danger-rgb) !important); /* Red border color when checked */
        }
        /* AutoCompleteMain.scss */

        .autosuggest-container {
          position: relative;
          width: 100%;
          margin: 0;
          padding: 0;
          margin-bottom: 20px;
        }
        
        .suggestions-container-open {
          position: absolute;
          z-index: 1000;
          width: 100%;
          background: white;
          border: 1px solid #ddd;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
          border-radius: 4px;
          margin-top: 2px;  /* Remove margin */
          padding: 0; /* Remove padding */
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          ul{
            margin: 0px;

            padding: 0px;
          }
        }
        
        .suggestion {
          padding: 10px 15px;  /* Reduced padding */
          cursor: pointer;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        
        .suggestion-highlighted {
          background-color: #f1f1f1;
        }
        .stepper-class{background:#FAF7F7;padding:0 3.75rem;}
        .stepper {
          display: flex;
          justify-content: space-between;
          padding:13px 0; position: relative;
          &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            width:100%;
            border-top: 1px solid #DFE0E5;
           z-index: 0;
          }
          .stepper-step {
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;
            cursor: pointer;
            padding: 0 10px;
            background-color:#FAF7F7 ;
            z-index: 1;
            &:last-child::after {
              display: none;
            }
            

          }
       
          .stepper-step.active .stepper-label {
            font-weight: 500;
            color:#fff;
            background-color:#000000;border-color:transparent;

          }
        
          .stepper-step.active::after {
            background-color:#F2FEFF;
          }

          .stepper-label {
            width:31px;
            height: 30px;
            padding:0px ;font-size:15px;line-height:29px;text-align: center;font-weight: 500;color:#707070;
            border-radius:100px;position: relative;
            z-index: 1;
            border:1px solid #707070;
            background-color:#FAF7F7 ;
          }
        }
        
        .custom-loader {
          width:150px;
          height:138px;
          color: #4E73DF;
          background:url(../assets/image/loader1.png) no-repeat 0 0;
          animation: sh7 1.5s infinite linear;
      }
      
      
      @keyframes sh7 {
          100% {
              transform: rotate(1turn)
          }
      }

      @media (max-width:992px) {
      .stepper .stepper-step::after {width: 335%;}
      .voter-box { width: 22%; }
      .bonne-sec{padding: 15px;}
      .table-wrapper{padding: 0 2.75rem;}
      .contacte-form{padding: 0 15px;}
    }
      @media (max-width:768px) {
        .stepper .stepper-step::after {width:235%;}
        .css-li58zp-MuiFormLabel-root-MuiInputLabel-root{white-space: wrap!important;font-size: 0.8rem !important;line-height: 1.18em!important;}
        .MuiInputBase-input.MuiOutlinedInput-input.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
          height: 1.8em !important;}
          .voter-box { width: 50%; }
          .stepper-class, .table-wrapper{padding: 0 1.25rem;}
          .bonne-sec{padding: 15px;}
          // .main-wrapper {width: 100%; }
          th .text-wrapper .table-label{font-size: 14px !important;}
        }
        @media (max-width:590px) {
          .stepper .stepper-step::after {width:190%;}
           .voter-box { width: 46%; }
           .custom-table td .table-label{font-size: 14px;}
          
          }
          @media (max-width:520px) {
            .stepper .stepper-step::after {width:300%;}
            .main-wrapper-component {
              padding:15px 0;
            }
            // .stepper .stepper-label {
            //   width:34px;
            //   height: 34px;font-size: 20px;
            //   line-height: 30px;
            // }
            // .stepper .stepper-step::before{
            //   top:-3px;
            //   left:-2px;
            //   width:39px;
            //   height:40px;
            //   background:url(../assets/image/step-border-mob.svg) no-repeat 0 0;
            //  }
          }


          @media (max-width:420px) {
            .stepper .stepper-step::after {width:247%;}
            .custom-table thead td .table-label{font-size: 14px;}
          }
          @media (max-width:401px) {
            .voter-box, .voter-box2{ width: 100%; }
            .voter-box-grid-main, .voter-box-grid-main2 {max-width: 90%;}
          // .main-wrapper-component{height: 116vh;}
          }